



import { Component, Prop, Vue } from 'vue-property-decorator';
import Player, { Options } from '@vimeo/player';

function defaultOptions(): Options {
  return {
    id: 0,
    width: 1920,
    height: 1080,
  };
}

@Component({})
export default class VideoPlayer extends Vue {
  @Prop({ default: defaultOptions }) private options!: Options;

  get playerAttrs() {
    return {
      ref: 'player',
      class: 'dn-video-player',
    };
  }

  public player!: Player;

  /**
   * init - Initialize Vimeo Player
   */
  public init() {
    const el = this.$refs.player as HTMLElement;
    this.player = new Player(el, this.options);
    this.player.on('play', this.onPlay.bind(this));
    this.player.on('pause', this.onPause.bind(this));
  }

  onPlay() {
    this.$emit('play');
  }

  onPause() {
    this.$emit('pause');
  }

  public mounted() {
    this.init();
  }
}
